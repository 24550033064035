// get_host_category
export const GET_HOST_CATEGORY = "GET_HOST_CATEGORY";

//Handle Open Close Dialog
export const OPEN_HOST_CATEGORY_DIALOG = "OPEN_HOST_CATEGORY_DIALOG";

//handle created category Dialog
export const CREATE_HOST_CATEGORY = "CREATE_HOST_CATEGORY";

// handle close Dialog
export const CLOSE_HOST_REQUEST_DIALOG = "CLOSE_HOST_REQUEST_DIALOG";

// handle close Dialog (Host Category)
export const CLOSE_HOST_CATEGORY_DIALOG = "CLOSE_HOST_CATEGORY_DIALOG";

// handel Edit host Request
export const EDIT_HOST_REQUEST = "EDIT_HOST_REQUEST";

// handle host request accept 
export const ACCEPT_HOST_REQUEST = "ACCEPT_HOST_REQUEST";   
