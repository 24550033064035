import * as ActionType from "./hostCategory.type";

const initialState = {
  hostCategory: [],
  dialog: false,
  dialogData: null,
};

export const hostCategoryReducer = (state = initialState, action) => {
  switch (action.type) {

    case ActionType.GET_HOST_CATEGORY:
      return {
        ...state,
        hostCategory: action.payload,
      };

    // open dialog
    case ActionType.OPEN_HOST_CATEGORY_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    

    // close dialog
    case ActionType.CLOSE_HOST_REQUEST_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

      case ActionType.CLOSE_HOST_CATEGORY_DIALOG:
        return {
          ...state,
          showDialog: false,
          dialog: false,
          dialogOpen: false,
          dialogData: null,
          formData: {}, 
          hostCategory: [...state.hostCategory, action.payload],
          // hostCategory: state.hostCategory.map((data) =>
          //   action.payload
          // ),
          
        };
     
    case ActionType.EDIT_HOST_REQUEST:
      return {
        ...state,
        hostCategory: state.hostCategory.map((data) =>
          data._id === action.payload.id ? action.payload.editHost : data
        ),
      };

    case ActionType.ACCEPT_HOST_REQUEST:
      return {
        ...state,
        hostCategory: state.hostCategory.filter(
          (hostAccept) => hostAccept._id !== action.payload && hostAccept
        ),
      };
    default:
      return state;
  }
};
